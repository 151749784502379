.productClient_wrapper {
  display: flex;
  /* gap: 40px; */
  justify-content: center;
  margin: 10px 0;
  width: 100%;
}
.productClient_wrapper > * {
  margin: 0 20px;
}

.productClient_photos {
  /* max-width: 27%; */
  /* min-width: 40vw; */
  max-width: 60vh;
  overflow: hidden;
  /* max-height: 50vh;
  object-fit: contain;
  overflow: hidden; */
}

.productClient_content {
  width: 50vh;
  min-width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 10px; */
  margin-top: -5px;
}
.productClient_content > * {
  margin: 5px 0;
}

.productClient_linkBack {
  margin: 10px 0 0 20px;
  text-align: left;
  font-size: 14px;
  /* font-weight: 300; */
  font-family: "Sofia Sans", sans-serif;
}
a:visited,
.productClient_linkBack > a:link {
  color: black;
}

.productClient_title {
  font-weight: 700;
  font-size: 20px;
}
.productClient_price {
  font-size: 20px;
  font-weight: 700;
}
.prodClient_price_box {
  display: flex;
  align-items: center;
  position: relative;
}
.productEditIcon {
  position: absolute;
  right: -40px;
  top: 0;
}
.prodClient_price_box > img {
  object-fit: contain;
  width: 10px;
  margin-left: 3px;
}

.productClient_options {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  /* border: 1px solid var(--color-forBtn);
  border-radius: var(--border-radius); */
}

.productClient_btn {
  margin-left: -1px;
  font-size: 18px;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  border-left-color: #d8d8d8;
  border-right-color: #d8d8d8;
  /* border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8; */
  display: flex;
  align-items: center;
}

.productClient_btn_passive:hover {
  color: var(--color-forBtn);
}

.productClient_btn_first {
  /* border-left: 1px solid #d8d8d8; */
}

.productClient_btn_active {
  background-color: var(--color-forBtn);
  color: var(--color-bg);
}

.productClient_descriptions {
  margin-top: 10px;
}

.productClient_components {
  margin-top: 10px;
  text-align: left;
  line-height: 1.5;
}

.productClient_components ul {
  line-height: 1.3;
}
