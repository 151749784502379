.about_wrapper {
  font-size: 18px;
  margin: 20px;
  /* text-align: left; */
}
#map {
  width: 700px;
  height: 450px;
  margin: 10px auto;
}
.about_info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 80%;
  margin: 10px auto;
}
.about_item img {
  width: 35px;
  margin-right: 10px;
}
.about_item {
  display: flex;
  align-items: center;
  /* gap: 10px; */
  text-align: left;
  min-width: 225px;
  padding-top: 10px;
}
.about_contacts {
  display: flex;
  justify-content: center;
  /* gap: 40px; */
  margin-top: 30px;
}
.about_contacts > *:first-child {
  margin-right: 40px;
}

.about_contact_item {
  display: flex;
  align-items: center;
  /* gap: 5px; */
  margin: 0 -2px;
}
.about_contact_item > * {
  margin: 0 2px;
}
.about_contact_item img {
  width: 50px;
}
