.productList_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 235px);

  justify-content: space-around;
  grid-gap: 10px;
}

.productList_item {
  /* height: 300px; */
  /* border: 1px solid black; */
  padding-bottom: 10px;
}
.productList_list_noProduct {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  font-size: 18px;
}

.filterPanel_priceActive {
  height: 24px;
  display: flex;
  border-radius: var(--border-radius);
  border: 1px solid var(--color-forBtn);
  background-color: var(--color-forBtn);
  color: white;
  padding: 2px;
  align-items: center;
}

#filterPanel_cancel {
  margin: 5px 0 0 2px;
  /* transform: translateY(20%); */
}
#filterPanel_cancel svg {
  width: 20px;
  height: 20px;
}
