.adminOrders_wrapper {
  margin-top: 10px;
}

.adminOrders_table .evenRow {
  background: var(--color-forTitle) !important;
}

.adminOrders_table {
  margin: 0 auto;
  text-align: center;
}

#prod_table_price {
  text-align: center;
}
.adminOrders_table_photo {
  max-width: 50px;
  width: 100%;
  height: 100%;
  transition: transform 0.2s;
}

.adminOrders_table_photo:hover {
  transform: scale(2);
}
.adminOrders_panel_up {
  margin: -15px 0 5px;
  display: flex;
  align-items: flex-end;
  gap: 20px;
}
.access_table_sort {
  cursor: pointer;
}

.table_sort_asc::after {
  content: " 🠕";
}
.table_sort_desc::after {
  content: " 🠗";
}

.orderLink {
  font-weight: 500;
  color: rgb(38, 38, 241);
  cursor: pointer;
}
.orderLink:hover {
  font-weight: bold;
}

.adminOrders_popUp {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #22222287;
  bottom: 0;
  position: fixed;
}

.ao_popUp {
  width: 80vw;
  min-height: 80vh;
  max-height: 90vh;
  position: relative;
  overflow: auto;
  display: flex;
  background-color: var(--color-forTitle);
  border-radius: 3px;
  flex-direction: column;
}
.aop_close {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.aop_title {
  font-size: 22px;
  padding: 5px 0 10px;
  background-color: #ebe0bc;
}

.aop_content {
  margin: 5px;
  display: flex;
  text-align: left;
  gap: 1%;
  justify-content: center;
}
.aop_infoItem {
  padding: 5px;
}
.aop_infoItem span,
.aop_contacts label {
  font-weight: 700;
}

.aop_orderInfo {
  width: 32%;
}
.aop_contacts {
  width: 32%;
}
.aop_productList {
  width: 33%;
}

.aop_photo img {
  width: 70px;
  height: 70px;
  overflow: hidden;
}

.aop_productItem {
  margin-top: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--color-forBtn);
  position: relative;
}

.aop_info_sum {
  text-align: left;
  padding-left: 5px;
  font-size: 12px;
}

.dd_option_aop {
  padding: 3px 9px;
  border: 1px solid #ffffff38;
  transition: all 0.1s;
  border-radius: 3px;
}
.dd_option_aop:hover {
  transform: scale(1.05);
}
