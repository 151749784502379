@media only screen and (max-width: 1005px) {
  /* FOR CATALOG */
  /* FOR PRODUCT PAGE */
  /* FOR STATIC PAGE */
  /* FOR OTHER */
  /* FOR BASKET */

  .newOrder_wrapper {
    margin: 0 auto;
    grid-template-columns: 1fr 1fr !important;
    max-width: 500px;
  }
  .newOrder_products {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .catalog_filterPanel {
    align-self: center !important;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (max-width: 770px) {
  /* FOR CATALOG */
  .catalog_content {
    margin: 10px 0 10px !important;
  }
  .catalog_navBar {
    display: none !important;
  }
  .productList_list {
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  div.filterPanel_btn {
    padding: 7px !important;
  }

  .filterPanel_priceActive {
    height: 34px !important;
  }

  .dd_option {
    padding: 9px !important;
  }
  .dd_option_input_ok {
    width: 25px !important;
    height: 25px !important;
  }
  .pd_btn {
    font-size: 14px !important;
    padding: 8px 15px !important;
  }

  /* FOR PRODUCT PAGE */
  /* FOR STATIC PAGE */
  #map {
    width: 100% !important;
  }
  /* FOR OTHER */
  .icon_header {
    width: 27px !important;
    height: 27px !important;
  }
  .icon_amount {
    width: 14px !important;
    height: 14px !important;
    font-size: 10px !important;
    bottom: -6px !important;
  }
  .header_btnBar {
    display: none !important;
  }
  .burgermenu_header {
    display: block !important;
  }

  /* FOR BASKET */
  .checkboxControl {
    padding: 8px 0 0;
  }
}
@media only screen and (max-width: 700px) {
  /* FOR CATALOG */

  .productList_list {
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 5px !important;
  }
  /* FOR PRODUCT PAGE */
  /* FOR STATIC PAGE */
  /* FOR OTHER */

  /* FOR BASKET */
}

@media only screen and (max-width: 600px) {
  /* FOR CATALOG */
  .image_galery_navBtn {
    display: none !important;
  }
  #filterPanel_searchInp {
    margin-top: -10px !important;
  }

  /* FOR PRODUCT PAGE */
  /* FOR STATIC PAGE */
  .about_contacts {
    flex-direction: column !important;
    align-items: center !important;
  }
  .about_contact_item {
    margin: 10px -2px 0 !important;
  }
  /* .review_wrapper {
    margin: 10px 0 10px 30px !important;
  } */

  /* FOR OTHER */

  .header_title {
    font-size: 24px !important;
    padding: 12px !important;
  }
  /* FOR BASKET */
  .app_content {
    padding: 0 10px;
    margin-left: -4px;
  }
  .newOrder_wrapper {
    grid-template-columns: 1fr !important;
    max-width: 100% !important;
    grid-gap: 0px !important;
  }
  .newOrder_products {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .newOrder_nameCol,
  .newOrder_addressCol,
  #note_message {
    justify-self: center !important;
    width: 400px !important;
  }
  .newOrder_nameCol > .form__group,
  .newOrder_addressCol > .form__group {
    width: 100% !important;
  }
  .newOrder_addressCol {
    justify-self: center !important;
  }
  .newOrder_pi_price_box,
  .products_info {
    margin-left: 10px !important;
  }
}

@media only screen and (max-width: 482px) {
  /* FOR CATALOG */

  .pi_price_box,
  .item_title {
    margin-top: 5px !important;
  }

  .filterPanel_priceActive {
    margin-top: 5px !important;
  }
  /* FOR PRODUCT PAGE */

  .productClient_wrapper {
    flex-direction: column;
    align-items: center;
  }
  .productClient_photos {
    min-width: 100vw !important;
    margin-bottom: 10px !important;
  }
  .productClient_content {
    display: block !important;
    min-width: 100vw !important;
    margin: 0 auto;
  }
  .productClient_options {
    justify-content: center;
    margin-bottom: 10px !important;
  }
  .prodClient_price_box {
    display: inline-flex !important;
  }
  .product_basket_btn {
    display: inline-flex !important;
    margin-left: 30px !important;
  }
  .productClient_components {
    margin: 0 auto !important;
    text-align: center !important;
  }

  /* FOR STATIC PAGE */
  .about_wrapper {
    margin: 10px !important;
  }
  .gisReview_content {
    flex-direction: column;
  }
  .head_rightPanel {
    flex-direction: column;
    align-items: flex-end;
  }

  .pay_box > div:first-child {
    margin-right: 10px !important;
  }
  /* FOR OTHER */

  .footer_nav {
    display: none !important;
  }
  .footer_socNetwork {
    width: auto !important;
    margin: 0 7px 0 -3px;
  }
  .footer_socNetwork img {
    width: 35px !important;
  }
  .footer_links {
    justify-content: flex-end !important;
  }
  .footer_bottom {
    margin-top: -18px;
    text-align: left !important;
  }
  /* FOR BASKET */
  .newOrder_productItem {
    min-width: 100% !important;
  }

  .newOrder_productItem_text {
    flex-direction: column;
    width: 50% !important;
    align-items: center !important;
    margin-left: 10px !important;
  }
  .newOrder_productItem_text > * {
    margin-top: 5px !important;
  }
  .newOrder_nameCol,
  .newOrder_addressCol,
  #note_message {
    justify-self: center !important;
    width: 100% !important;
  }
}
@media only screen and (max-width: 440px) {
  /* FOR CATALOG */

  /* FOR PRODUCT PAGE */
  /* FOR STATIC PAGE */
  .review_client_addReview {
    text-wrap: wrap !important;
  }
  /* FOR OTHER */
  /* FOR BASKET */
}

@media (hover: hover) and (pointer: fine) {
  .rose_btn:hover {
    transform: scale(1.05);
    box-shadow: 0 0 2px 0 var(--color-forBtn) inset,
      0 0 5px 1px var(--color-forBtn);
  }
  .svgEdit svg:hover,
  .svgShow svg:hover,
  .svgOK svg:hover {
    fill: var(--color-forBtn);
  }

  .svgHide svg:hover,
  .svgDel svg:hover {
    fill: red;
  }

  .svgPlus svg:hover {
    fill: var(--color-forTitle);
  }

  .dd_option:hover {
    color: #fff;
    transform: scale(1.05);
    background-color: var(--color-forBtn);
  }
}
