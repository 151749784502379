.app_footer {
  background-color: var(--color-forTitle);
  padding: 10px;
  margin-top: 10px;
  min-width: 300px;
  color: rgb(135, 134, 134) !important;
}
.footer_links {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}

.footer_nav {
  margin: 0 -4px;
  text-decoration: underline;
  display: inline-flex;
  /* gap: 7px; */
  justify-content: flex-end;
  color: rgb(135, 134, 134) !important;
  font-size: 14px;
}
.footer_nav .active {
  font-weight: normal;
}
.footer_nav a {
  margin: 0 4px;
  color: rgb(135, 134, 134) !important;
}
.footer_nav a:hover {
  font-weight: bold;
}
.footer_socNetwork {
  width: 50%;
  margin: 0 7px 0 -3px;
  display: inline-flex;
  /* gap: 5px; */
  justify-content: flex-end;
  align-items: center;
}
.footer_socNetwork > a {
  margin: 0 3px;
}
.footer_socNetwork img {
  width: 20px;
}
.footer_bottom {
  font-size: 14px;
  justify-self: flex-start;
}
