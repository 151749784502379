.header_wrapper {
  /* min-width: 765px; */
  display: flex;
  flex-direction: column;
}
.header_NavBarWrapper {
  background-color: var(--color-rozeLogo);
}

.header_NavBarContent {
  padding: 5px 20px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.header_logo {
  /* width: 160px; */
  height: 40px;
  object-fit: contain;
  overflow: hidden;
}
.header_logo img {
  /* width: 100%; */
  height: 100%;
}

.header_btnBar {
  display: flex;
  /* gap: 10px; */
  align-items: center;
  margin: 0 -5px 0 5vw;
  white-space: nowrap;
  font-weight: 300;
  font-family: "Sofia Sans", sans-serif;
}
.header_btnBar > a {
  margin: 0 5px;
}
.header_btnBar a:link,
.header_btnBar a:visited {
  color: #f0ede2;
}
.header_btnBar a:hover,
.header_btnBar a:active {
  font-weight: 400;
}
.header_icons img {
  cursor: pointer;
}
.header_icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* gap: 10px; */
  /* width: 100%; */
  flex-grow: 2;
  /* margin-right: 10px; */
  margin: 0 5px 0 -5px;
}
.header_icons > * {
  margin: 0 5px;
}

.header_globalTitle {
  font-weight: 400;
  font-family: "Sofia Sans", sans-serif;
  font-size: 12px;
  padding: 7px;
  background-color: var(--color-forTitle);
  color: black;
}

.header_globalTitle_message {
  font-weight: 400;
  font-family: "Sofia Sans", sans-serif;
  font-size: 16px;
  padding: 7px;
  background-color: var(--color-forTitle);
  color: var(--color-rozeLogoRed);
}

.header_title {
  margin-top: 10px;
  font-weight: 400;
  font-family: "Sofia Sans", sans-serif;
  font-size: 28px;
  padding: 15px;
  background-color: var(--color-forTitle);
  color: black;
}

.header_search_input {
  width: 0px;
  background-color: var(--color-forTitle);
  border-radius: 3px;
  padding: 3px;
  font-size: 16px;
  transition: width 0.3s;
  /* display: none; */
  /* opacity: 0; */
  visibility: hidden;
  position: absolute;
}

.show {
  position: relative;
  width: 150px;
  visibility: visible;
  /* opacity: 1; */
  /* display: block; */
}
.header_backet {
  position: relative;
}
.icon_header {
  width: 19px;
  height: 19px;
  stroke: #fff;
  stroke-width: 1.2;
  cursor: pointer;
}

.icon_amount {
  position: absolute;
  bottom: -2px;
  right: 0;
  width: 10px;
  height: 10px;
  padding: 1px;
  /* padding: 2px 2.5px 1px 4px; */
  border-radius: 100%;
  font-weight: bold;
  font-size: 7px;
  background-color: #ff0000;
  color: #fff;
  font-family: "Sofia Sans", sans-serif;
}

.burgermenu_header {
  display: none;
  z-index: 100;
}

#menuToggle {
  display: block;
  position: relative;
  /* top: 50px;
  left: 50px; */
  top: 9px;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;

  transition: color 0.3s ease;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #fff;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: absolute;
  width: 100vw;
  right: -20px;
  margin: -45px 0 0 -50px;
  padding: 20px;
  background: var(--color-forTitle);
  border-bottom: 3px solid var(--color-rozeLogo);
  border-radius: 3px;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(0, -100%);
  transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

#menu a {
  padding: 5px 0;
  font-family: "Sofia Sans", sans-serif;
  /* font-size: 22px; */
}
#menu a svg {
  width: 10px;
  height: 10px;
  filter: drop-shadow(0 1px 1px #1a1a1a);
  stroke: #1a1a1a;
  /* padding: 0 5px; */
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ div {
  transform: none;
}

.menuBrg_svgIcon {
  width: 10px;
  height: 10px;
  filter: drop-shadow(0 1px 1px #1a1a1a);
  stroke: #1a1a1a;
  transition: all 0.3s;
  margin-left: 5px;
}

.menuBrg_svgIcon_open {
  transform: rotateX(180deg) translateY(-140%);
}

.menuBrg_catalog {
  display: flex;
  justify-content: center;
  /* gap: 5px; */
  align-items: baseline;
  margin-left: 15px;
}

.categoriesBrg {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* transition: all 0.4s ease-in; */
  max-height: 100%;
}
.hide_categoriesBrg {
  /* transition: all 0.4s ease-out; */
  max-height: 0;
}
