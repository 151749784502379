.app {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.app_content {
  flex-grow: 1;
  align-self: center;
  max-width: 1200px;
  /* min-width: 765px; */
  width: 100%;
  padding: 0 20px;
  margin-left: -10px;
}

.active {
  font-weight: 600;
}

.rose_btn {
  border-radius: var(--border-radius);
  border: 1px solid var(--color-forBtn);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  padding: 7px;
  cursor: pointer;
}
/* .rose_btn:hover {
  transform: scale(1.05);
  box-shadow: 0 0 2px 0 var(--color-forBtn) inset,
    0 0 5px 1px var(--color-forBtn);
} */
.rose_btn:active {
  transform: scale(1) !important;
}

.rose_table thead th {
  font-weight: 400;
  background: var(--color-rozeLogo);
  color: #fff;
}

.rose_table tr {
  background: #f4f7f8;
  border-bottom: 1px solid #fff;
  margin-bottom: 5px;
}

/* .rose_table tr:nth-child(even) {
  background: #e8eeef;
} */

.rose_table th,
td {
  text-align: left;
  padding: 10px;
  font-weight: 300;
}

.svgTool svg {
  fill: none;
  cursor: pointer;
  transition: all 0.2s;
}

.admin_tools {
  display: flex;
  gap: 5px;
  align-items: center;
}
.svgEdit svg {
  width: 25px;
}

.svgHide svg,
.svgShow svg,
.svgDel svg,
.svgCancel svg {
  width: 30px;
  stroke-width: 66.6667;
}
.svgOK svg {
  width: 30px;
  stroke: #323232;
}
.svgPlus svg {
  width: 40px;
  stroke: var(--color-forBtn);
}
/* .svgEdit svg:hover,
.svgOK svg:hover {
  fill: var(--color-forBtn);
}

.svgDel svg:hover {
  fill: red;
}

.svgPlus svg:hover {
  fill: var(--color-forTitle);
} */
