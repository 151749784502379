.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  /* width: 300px; */
}

.form__field {
  font-family: inherit;
  width: 100%;
  border-bottom: 2px solid var(--color-forBtn);
  outline: 0;
  font-size: 1.1rem;
  color: #000;
  padding: 7px 0;
  border-radius: var(--border-radius);
  -webkit-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  transition: border-color 0.2s;
  padding-left: 5px;
}
.field__valid {
  background: #e8e2cfa1;
}
.field__notValid {
  background: red;
}
textarea.form__field {
  resize: vertical;
}
.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1.1rem;
  cursor: text;
  top: 20px;
  padding-left: 5px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #222;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: var(--color-forBtn);
  font-weight: 700;
}
.form__field:focus {
  padding-bottom: 6px;
  /* font-weight: 700; */
  border-width: 3px;
  border-image-slice: 1;
}
/* reset input */
.form__field:required {
  box-shadow: none;
}
.form__field:invalid {
  box-shadow: none;
}

.input_address {
  position: relative;
}
.input_address_options {
  position: absolute;
  /* top: 10px; */
  z-index: 10;
  cursor: pointer;
  background-color: var(--color-forTitle);
  width: 100%;
  text-align: left;
}
.input_address_options > div {
  padding: 3px;
  border-bottom: 1px solid var(--color-forBtn);
}
.input_address_options > div:hover {
  color: #fff;
  background-color: var(--color-forBtn);
}
.optionAddressOnFocus {
  color: #fff;
  background-color: var(--color-forBtn);
}

.hide_address_options {
  display: none;
}
