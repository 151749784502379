.slider_wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: #dad8ce30;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider_hide {
  display: none;
}
