.del_pay_wrapper {
  font-size: 18px;
  margin: 20px;
  /* text-align: left; */
}

.del_pay_delivery {
  margin-top: 20px;
  /* text-align: left; */
  line-height: 1.5;
}

.del_pay_pay {
  margin-top: 10px;
}
.del_pay_title {
  /* font-family: serif; */
  font-size: 22px;
}
.pay_box {
  /* width: 50%; */
  justify-content: center;
  margin: 5px auto;
  display: flex;
  /* gap: 10px; */
}
.pay_box > div:first-child {
  margin-right: 30px;
}
.pay_item img {
  width: 70px;
}
.del_box img {
  width: 35px;
}

.pay_info {
  font-size: 14px;
  max-width: 250px;
}

.del_box {
  margin: 0 auto;
  text-align: left;
  max-width: 400px;
}

.del_box_item {
  display: flex;
  align-items: center;
  /* gap: 5px; */
}
.del_box_item img {
  margin-right: 5px;
}
