/* .newOrder_wrapper {
  display: flex;
  width: 100%;
  gap: 10px;
  padding: 0 5px;
  flex-wrap: wrap;
}
.newOrder_products {
  min-width: 420px;
} */
.newOrder_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;

  width: 100%;

  grid-gap: 20px;
  padding: 0 5px;
  justify-content: center;
  /* flex-wrap: wrap; */
}
.newOrder_nameCol {
  justify-self: end;
}
.newOrder_products {
  /* width: 40%; */
  /* min-width: 420px; */
}

.newOrder_wrapper a {
  color: black;
}
.newOrder_productItem {
  margin-top: 10px;
  /* margin: 10px -10px 0; */
  min-width: 410px;
  padding: 5px;
  display: flex;
  align-items: center;
  /* gap: 20px; */
  border-bottom: 1px solid var(--color-forBtn);
  position: relative;
}
.newOrder_productItem_text {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 -10px;
}
/* .newOrder_productItem > *, */
.newOrder_productItem_text > * {
  margin: 0 10px;
}
.pi_photo img {
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.pi_name {
  flex-grow: 2;
  min-width: 110px;
}
.pi_amount {
  /* flex-grow: 1; */
  display: flex;
  /* gap: 5px; */
}
.pi_amount > div[title="Количество в заказе"] {
  margin: 0 5px;
}
.pi_amount div {
  padding: 5px;
}
.pi_amount_op {
  width: 30px;
  height: 30px;
  background-color: #2222222c;
  border-radius: 100%;
  cursor: pointer;
}

.pi_price {
  white-space: nowrap;
}
.newOrder_products_price_box {
  margin-top: 5px;
}
.newOrder_pi_price_box,
.newOrder_products_price_box {
  display: flex;
  align-items: center;
}
.newOrder_products_price_box > img,
.newOrder_pi_price_box > img {
  object-fit: contain;
  width: 8px;
  margin-left: 3px;
}

.pi_del_product {
  position: absolute;
  right: 2px;
  top: 2px;
}
.pi_del_product svg {
  fill: none;
  cursor: pointer;
  transition: all 0.2s;
  width: 30px;
  stroke-width: 40;
}
.pi_del_product svg:hover {
  stroke-width: 60;
}

.products_total_price {
  text-align: left;
}

.products_info {
  text-align: left;
  margin-top: 5px;
  font-size: 12px;
}

.newOrder_btn {
  margin-top: 10px;
  background-color: var(--color-forBtn);
  color: #fff;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
  font-size: 17px;
}

.newOrder_btn_disable {
  opacity: 0.5;
  cursor: auto;
}
.newOrder_btn_disable:hover {
  transform: none;
}

.newOrder_orderInfo {
  margin-top: 20vh;
  font-size: 18px;
}
.newOrder_orderInfo div {
  margin-top: 15px;
}

.pi_note_mess {
  overflow: hidden;
  transition: all 0.4s ease-in;
  max-height: 100%;
}
.hide_pi_note_mes {
  transition: all 0.4s ease-out;

  max-height: 0;
  /* scale: 0; */
}

.checkboxControl {
  margin-top: 7px;
  display: flex;
  /* gap: 5px; */
  align-items: center;
}

/* .form-control--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
} */

.checkboxControl input[type="checkbox"] {
  margin-right: 5px;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  font: inherit;
  color: var(--color-forBtn);
  width: 1.1em;
  height: 1.1em;
  border: 0.13em solid currentColor;
  border-radius: 0.15em;
  /* transform: translateY(-0.075em); */

  display: grid;
  place-content: center;
}

.checkboxControl input[type="checkbox"]::before {
  content: "";
  width: 0.6em;
  height: 0.6em;
  stroke: var(--color-forBtn);

  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: var(--color-forBtn);
}

.checkboxControl input[type="checkbox"]:checked::before {
  transform: scale(1);
}

/* input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
} */

.checkboxControl input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

/* #time_delivery {
  cursor: pointer;
} */
input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.newOrder_popUp {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #22222287;
  bottom: 0;
  position: fixed;
}

.no_popUp {
  width: 80vw;
  min-height: 80vh;
  max-height: 90vh;
  position: relative;
  overflow: auto;
  display: flex;
  background-color: var(--color-forTitle);
  border-radius: 3px;
  flex-direction: column;
}
.nop_close {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.nop_title {
  font-size: 18px;
  padding: 5px 20px 10px;
  background-color: #ebe0bc;
}

.nop_content {
  margin: 5px;
  text-align: left;
}
.nop_content p {
  margin-top: 10px;
}

.rules {
  font-size: 12px;
}
.rules > span {
  color: var(--color-forBtn);
  cursor: pointer;
}
